.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.modal-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal-text h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
}

.modal-text p {
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}

.modal-text span {
  font-weight: 600;
}

.event-info {
  padding-top: 8px;
}

.event-free {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.register-info {
  font-size: 16px;
  margin: 4px 0 0;
}

.register-button {
  background-color: var(--blue);
  color: white;
  padding: 24px 32px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  display: inline-block;
}

.register-button:hover {
  background-color: var(--blue);
  scale: 1.02;
  color: white;
}

/* Responsive styles */
@media (max-width: 640px) {
  .modal-content {
    padding: 24px;
  }

  .modal-text h2 {
    font-size: 20px;
  }

  .modal-text p {
    font-size: 16px;
  }

  .event-free {
    font-size: 16px;
  }

  .register-info {
    font-size: 14px;
  }

  .register-button {
    padding: 20px 28px;
    font-size: 16px;
  }
}
