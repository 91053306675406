@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,600;1,700;1,800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"), url(fonts/AvenirNext.ttf) format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 300;
  src: local("AvenirNext"), url(fonts/AvenirNext-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 400;
  src: local("AvenirNext"), url(fonts/AvenirNext-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 500;
  src: local("AvenirNext"), url(fonts/AvenirNext-Regular.ttf) format("truetype");
}

:root {
  --blue: #001c52;
  --mint: #9bdcd2;
  --mint-medium: #c3eae4;
  --mint-light: #e6f6f4;
}

.bg-mint {
  background: var(--mint);
}

.text-white {
  color: white;
}

.text-mint {
  color: var(--mint);
}

.counter-label {
  color: black;
}

@media (max-width: 900px) {
  .counter-label {
    color: white;
  }
}

#loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--blue);
}

.logos-heading {
  text-align: center;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  color: #333;
  font-weight: 600;
  line-height: 1.3;
}

.loginContainer {
  width: 500px;
  max-width: 90vw;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 20px 0 #ffe60331;
}
.loginContainer .logo {
  text-align: left;
  display: none;
  margin-bottom: 20px;
}
.loginContainer h1 {
  color: white;
  text-align: left;
  font-size: 24px;
  font-weight: 400;
}

.loginContainer input {
  width: 100%;
  margin: 10px 0;
  height: 40px;
  border: 1px solid var(--blue);
  border-radius: 4px;
  padding-left: 10px;
  color: black;
}
.loginContainer form a {
  text-align: left;
  color: #ffffff;
  width: 100%;
  text-decoration: none;
}
.loginContainer .signIn {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  border: 1px solid var(--blue);
  border-radius: 20px;
  color: #fff;
  background: var(--blue);
  font-size: 16px;
  padding: 5px 20px;
  margin-bottom: 20px;
}
.loginContainer input:focus {
  outline: none;
  box-shadow: 0 0 10px 0 #ffffff50;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
  font-family: "AvenirNext", sans-serif !important;
  scroll-behavior: smooth;
}
html,
#root,
.zaslepka {
  overflow-x: hidden;
  width: 100vw;
}
.zaslepka {
  background: #a1b9d0;
}

.landingpage {
  overflow-x: hidden;
  width: 100vw;
}
.douglasHead svg {
  max-width: 80%;
}

.douglasHead {
  text-align: center;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  flex-direction: column;
}

.douglasHead img {
  max-height: 240px;
  width: auto;
}
.counterWrap {
  width: 100%;
}

.flexCounter {
  display: flex;
  align-items: center;
  padding: 3rem 0;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}
.counter {
  width: 15vw;
  text-align: center;
}

.counter p {
  color: white;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 103px; /* 147.143% */
  letter-spacing: -1.5px;
  margin-bottom: 0;
}
.imageWrapperUnderC {
  margin: auto;
  padding: 0 2rem;
  text-align: center;
  width: 100%;
  max-width: 2000px;
  overflow: hidden;
}
.imageWrapperUnderC img {
  width: 100%;
  height: auto;
  display: block;
}
.backgroundCounter {
  border-radius: 13px;
  background: white;
  height: 12vw;
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 103px; /* 114.444% */
  letter-spacing: -4.5px;
  justify-content: center;
}

.bottomSectionzaslepka {
  background: white;
  text-align: center;
}

.bottomSectionzaslepka img {
  max-height: 200px;
}

.content {
  position: relative;
  padding-top: 80px;
}
.content .imageWeeks {
  position: absolute;
  max-width: 400px;
  left: 0;
  top: 0;
}

.content .buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px 0;
}

.buttonRegister {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 400% */
  width: 200px;
  border: none;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.content .buttons .regulation {
  color: #fff;
  background: #ffdc60;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 400% */
  width: 200px;
  border: none;
  border-radius: 10px;
}
.buttonRegister .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffdc60;
  position: absolute;
  left: -25px;
}
@media only screen and (max-width: 1400px) {
  .backgroundCounter {
    width: 20vw;
    height: 18vw;
    font-size: 70px;
    background: white;
  }
  .counter {
    width: 20vw;
  }
}
.mobileMenuOpen {
  display: none;
}

.mobileMenuOpen .menu a {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 900px) {
  .mobileMenuOpen {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: var(--blue);
    flex-direction: column;
    padding: 20px;
    z-index: 99;
  }
  .backgroundCounter {
    width: 100%;
    height: 30vw;
    font-size: 50px;
  }
  .flexCounter {
    flex-wrap: wrap;
  }
  .counter {
    width: 40%;
    position: relative;
  }
  .counter p {
    position: absolute;
    bottom: 0px;
    width: 100%;
    color: black;
    text-align: center;
  }
  .flexCounter {
    padding: 2rem 0;
  }
}

@media only screen and (max-width: 500px) {
  .douglasHead {
    padding-top: 2rem;
  }
  .flexCounter {
    row-gap: 10px;
  }
  .flexCounter .backgroundCounter {
    min-height: 120px;
  }
}

/*  LANDING  */

.bgBlue {
  background: var(--blue);
}

.landingpage .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 98;
  background: transparent;
  transition: all 0.3s ease;
}

.landingpage .header.scrolled {
  background: var(--blue);
}

.landingpage .flexHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;
}

.landingpage .menu {
  padding-left: 30vw;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.landingpage .menu a {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.landingpage h2,
h2#lokalizacja {
  font-size: 60px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 0;
  letter-spacing: -0.05em;
  text-align: center;
}

.content p.desc {
  margin: 1rem 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.05em;

  text-align: center;
}
.map {
  width: 80%;
  height: 60vh;
  margin: auto;
  padding-bottom: 3rem;
}

.map iframe {
  width: 100%;
  height: 100%;
}

.registerCont {
  text-align: center;
  padding-bottom: 4rem;
  position: relative;
  padding-top: 4rem;
}

.registerCont h3 {
  color: #fff;
  text-align: center;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 103px; /* 114.444% */
  letter-spacing: -4.5px;
  margin-bottom: 2rem;
}

.registerCont form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 34px;
  z-index: 99;
}

.registerCont input,
.registerCont select {
  width: calc(50% - 17px);
  border-radius: 5px;
  border: 1px solid #eff1f6;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(189, 196, 205, 0.13);
  height: 60px;
  padding: 10px;
  z-index: 9;
}

.registerCont input:focus,
.registerCont select:focus {
  outline: none;
}

.registerCont .circle1 {
  position: absolute;
  top: 90%;
  left: 0%;
}

.registerCont .circle2 {
  position: absolute;
  top: 35%;
  left: 90%;
  z-index: 1;
}

#topTargin img {
  width: 50vw;
}
.registerCont .circle3 {
  position: absolute;
  top: 20%;
  left: 60%;
  z-index: 1;
}

.registerCont .zgody {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 20px;
}
.registerCont .zgody label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
}
.registerCont .zgody button {
  width: 40px;
  border: none;
  border-radius: 13px;
  background: #d9d9d9;
  height: 40px;
}

.error {
  color: red;
}

.success {
  color: #ffffff;
}

.registerCont .zgody button#active {
  background: var(--mint);
  display: flex;
  justify-content: center;
  align-items: center;
}
.registerCont .zgody button#active:after {
  content: "✓";
  color: white;
  font-size: 20px;
}
.registerCont .zgody label a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--blue);
  font-weight: 700;
  align-items: center;
}

.registerCont .zgody label a svg {
  width: 90px;
}

.submitBut {
  width: 100%;
  background: white;
  color: black;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  line-height: 64px; /* 426.667% */
}
.notesBottomDang p {
  text-align: start;
  /* color: #f00; */
  color: darkred;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
}
.rowSimple {
  padding: 4rem 0;
  display: flex;
  flex-direction: row;
}

.buttonRegulamin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}

.landingpage {
  position: relative;
  overflow-x: hidden;
}

.bigCircle {
  position: absolute;
  height: 78vh;
  width: 78vh;
  border-radius: 50%;
  background: white;
  z-index: 1;
}

.bigCircle.top {
  top: -20vh;
  right: -20vh;
}

.header {
  z-index: 9;
  position: relative;
}
.landingpage .content {
  z-index: 9;
}

.rightSide {
  box-shadow: 0px 9px 14px rgba(169, 191, 212, 0.19);
  margin-right: 6vw;
  text-align: center;
  padding: 10px 50px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 400% */
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  border-radius: 10px;
}

.noDecor {
  text-decoration: none;
  background: white;
  border-radius: 10px;
}

.rightSide .stangeCircle {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  background: var(--blue);
}

.counterText {
  padding-top: 2rem;
  color: white;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.9px;
  line-height: 103px; /* 147.143% */
}

.myRow {
  display: flex;
  position: relative;
  justify-content: space-around;
}

.myRow .block {
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 250px;
  padding: 20px;
  justify-content: center;
  gap: 20px;
}
.myRow img {
  position: absolute;
}
.onPlace {
  margin-top: 5rem;
}
.onPlace ul {
  list-style-type: none;
  padding-left: 0;
}
.onPlace li {
  width: fit-content;
  margin: auto;
  color: #212121;
  font-size: 28px;
  font-style: normal;
  line-height: 50px;
  letter-spacing: -1.75px;
}
.onPlace h4 {
  color: #212121;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px; /* 142.857% */
  letter-spacing: -1.75px;
}

.myRow .block .counterBlock {
  color: var(--Text-Heading, #2d3958);
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 1 */
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 119.048% */
  letter-spacing: -0.84px;
  display: flex;
}

.myRow .block .counterBlock::after {
  content: "";
  margin-left: -20px;
  width: 50px;
  height: 50px;
  background: #886cff;
  display: block;
  border-radius: 50%;
  opacity: 0.1;
}
.myRow .block .counterBlock.second::after {
  background: #ef3737;
}
.myRow .block .counterBlock.third::after {
  background: #ff9513;
}
.myRow .block .counterBlock::after .myRow img {
  position: absolute;
}

.arrow1 {
  left: 20%;
  top: 10%;
  width: 20%;
  z-index: 9;
}

.arrow2 {
  left: 54%;
  top: 50%;
  width: 20%;
  z-index: 9;
}

.myRow .block p {
  text-align: center;
  color: #212121;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 127.778% */
  letter-spacing: -1px;
}
@media only screen and (max-width: 2700px) {
  .bigCircle {
    height: 60vh;
    width: 60vh;
  }
}
@media only screen and (max-width: 2248px) {
  .bigCircle {
    height: 55vh;
    width: 55vh;
  }
}
@media only screen and (max-width: 1586px) {
  .bigCircle {
    height: 52vh;
    width: 52vh;
  }
}

@media only screen and (max-width: 1400px) {
  .content h1 {
    font-size: 60px;
  }
  .landingpage .menu {
    padding-left: 15vw;
  }
  .arrow2 {
    width: 15%;
    left: 56%;
  }
  .arrow1 {
    width: 15%;
    left: 22%;
  }
  .bigCircle {
    height: 50vh;
    width: 50vh;
  }
}

@media only screen and (max-width: 1199px) {
  .myRow img {
    display: none;
  }
  .content h1 {
    font-size: 50px;
    padding-top: 5rem;
  }
  .myRow .block {
    width: 30%;
  }
  .landingpage .menu {
    padding-left: 10vw;
  }
  .landingpage h2 {
    font-size: 70px;
  }
  .registerCont h3 {
    font-size: 70px;
  }
  .bigCircle.top {
    height: 50vh;
    width: 50vh;
  }
}
.mobileMenu {
  display: none;
}

.mapBottom .bottomText {
  width: 500px;
  margin: auto;
  max-width: 90vw;
}
.mapBottom .bottomText h1,
.targiText {
  color: white;
  text-align: center;
  padding-left: 40px;
  font-size: 40px;
  font-weight: 200;
  line-height: 103px;
  letter-spacing: 45px;
  font-family: "Poppins", sans-serif;
}

#topTargin .targiText {
  font-size: 80px;
}

.bottomSpacer {
  height: 10px;
  width: 100%;
  background: var(--blue);
}

.mySwiper img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1074px) {
  .bigCircle {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .mobileMenu {
    display: block;
  }
  .landingpage .header {
    justify-content: end;
    padding: 10px 15px;
  }
  .content h1 {
    padding-top: 0;
    text-align: center;
    color: #212121;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 343.333% */
    letter-spacing: 15px;
  }

  .content #topTargin h1.targiText,
  p.targiText {
    padding-top: 0;
    text-align: center;
    padding-left: 20px;
    color: white;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 200;
    line-height: 50px; /* 343.333% */
    letter-spacing: 15px;
  }
  .landingpage .menu {
    display: none;
  }
  .rightSide {
    display: none;
  }
  .bigCircle.top {
    display: none;
  }

  #topTargin img {
    width: 70vw;
  }
  .landingpage h2,
  h2#lokalizacja {
    color: #212121;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 200% */
    letter-spacing: -1px;
  }
  .content p.desc {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    margin: 0;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -1px;
  }
  .counterText {
    color: white;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 572.222% */
    letter-spacing: -0.9px;
  }
  .counter p {
    color: black;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 515% */
    letter-spacing: -1px;
  }
  .imageWrapperUnderC img {
    width: 90vw;
  }
  .myRow {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .myRow .block {
    width: 90%;
    height: 200px;
  }
  .myRow img {
    display: none;
  }
  .onPlace h4 {
    font-size: 24px;
  }
  .onPlace li {
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    width: 90vw;
    letter-spacing: -0.9px;
  }
  .registerCont h3 {
    margin-top: 3rem;
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 343.333% */
    letter-spacing: -1.5px;
  }
  .registerCont input,
  .registerCont select {
    width: 100%;
  }
  .registerCont form {
    gap: 20px;
  }
  .registerCont .zgody button {
    width: 30px;
    height: 30px;
  }
  .registerCont .zgody label {
    font-size: 13px;
    text-align: left;
    width: 100%;
    line-height: 18px;
  }
  .registerCont .zgody label .d-flex {
    flex-wrap: wrap;
    width: 100%;
    text-align: start;
  }
  .targiText {
    color: white;
    text-align: center;
    font-size: 50px;
    font-weight: 300;
    line-height: 103px; /* 114.444% */
    letter-spacing: 45px;
  }
  .mapBottom .bottomText img {
    width: 80vw;
  }
  .map {
    height: 40vh;
  }
  .notesBottomDang p {
    font-size: 17px;
  }
  .registerCont .zgody label a {
    text-align: start;
    width: fit-content;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
    white no-repeat 96% !important;
}

select::-ms-expand {
  display: none;
}

.buttonsCoint button {
  padding: 6px 12px;
  background: white;
  border: 1px solid #00507e;
  font-size: 14px;
  color: #00507e;
}
.buttonsCoint button#active,
.buttonsCoint button:hover {
  background: #00507e;
  color: white;
}

.modalBG {
  position: fixed;
  background: #0000005b;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBG .modalInner {
  padding: 40px;
  background: white;
  border-radius: 10px;
  width: 700px;
  max-width: 90vw;
  position: relative;
}

.days-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}
.modalInner .close {
  position: absolute;
  top: 5px;
  right: 5px;
}
.modalInner .close svg {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: red;
}
.modalBG form input,
.modalBG form select {
  padding: 10px;
  height: 50px;
}
.modalBG form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

select optgroup {
  font-weight: 600;
  color: #00507e;
  font-size: 14px;
}

select option {
  font-weight: normal;
  font-size: 14px;
  padding: 5px 0;
}

select option:disabled {
  color: #999;
  font-style: italic;
}

.logo-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
}

.logo-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
}

.mySwiper {
  padding: 20px 0;
}

.swiper-slide {
  height: 100px; /* dostosuj wysokość według potrzeb */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
